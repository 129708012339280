import React from "react";
import {Link} from "gatsby";

// Variables
import {routes} from "../config";

// Images
import logo from "../../../images/logo.svg";

export default () => (
  <div className="pt-s">
    <div className="container flex flex-align flex-space relative pt-s">
      <Link href="/">
        <img className="nav-logo" src={logo} alt="TriMotion Logo" />
      </Link>
      <div className="flex flex-align">
        {routes.map((item, index) => (
          <Link className="ml-m" key={index} to={item.href}>
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  </div>
);
