import React from 'react';
import NavigationMobile from "./navigation-mobile";
import NavigationDesktop from "./navigation-desktop";

const Navigation = () => {
    return (
        <nav className="nav fixed z-index">
            <NavigationDesktop /> 

        </nav>
    );
};

export default Navigation;
